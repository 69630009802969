import { Injectable, ViewChild } from '@angular/core';
import {FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { } from 'googlemaps';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import {DatePipe} from '@angular/common';

@Injectable()
export class EventserviceService {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  currencies: string[] = ['NGR','EUR','GBP'];
  categories: string[] = ['Party','Club','Sports','Conference','Meet up'];
  saleOption: string[] = ['NO','YES']
  ticketTypes: string[] = ['Early Bird','REGULAR','VIP','VVIP','TABLE FOR 4', 'TABLE FOR 5', 'SECTION 1', 'ROW 1', 'SECTION 2', 'ROW 2'];
  policies: string[] = ['Free cancellation until 7 days before event start date', 'Non-refundable', 'Free cancellation until 1 day before event start']
  stringPattern = "[a-zA-Z 0-9,-_]*";
  mobnumPattern = "^[+][0-9]*"; 
  cityPattern = "[a-zA-Z ]*";
  datePattern = "[A-P 0-9 -/:]*";
  digitPattern = "[0-9]*"
  titleAlert:string = 'This field is required';
  emailError: string = 'Valid email required';
  errorAddress:string= 'This field is required and less than 10 characters';
  errorCity:string= 'This field is required and no special/alphanumeric characters allowed';
  errorContact:string= 'This field is required and should include the extension (+37254574731)';
  errorDefault:string='This is field is required and no special characters allowed';
  errorCurrency:string='Expected value e.g 10.0';
  errorMismatch:string='The two password fields must match';

  tomorrow: string = '';
  start: string = '';
  end: string = '';
  public options:any = {}


  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe) { 

    var date = new Date();
    this.tomorrow = this.datePipe.transform(date,"yyyy-MM-dd");
    this.options = {
      locale: { format: 'YYYY-MM-DD H:mm A' },
      timePicker: true,
      timePicker24Hour: true,
      alwaysShowCalendars: false,
      minDate: this.tomorrow
    }
    
  }


  public getCategories() {
    return this.categories;
  }


  // public options: any = {
  //   locale: { format: 'YYYY-MM-DD H:mm A' },
  //   timePicker: true,
  //   timePicker24Hour: true,
  //   alwaysShowCalendars: false,
  //   minDate: this.tomorrow,

  // };

  public addCustomTicket() {

    let tickets = this.formBuilder.group({
      tickettype: null,
      ticket_qty: [0, Validators.compose([Validators.required, Validators.pattern(this.digitPattern), Validators.maxLength(6)])],
      // ticket_amt: new FormControl({ value: 0, disabled: false}, Validators.compose([Validators.required,Validators.pattern(this.digitPattern),Validators.maxLength(6)])),
      ticket_amt:[0, Validators.compose([Validators.required, Validators.pattern(this.digitPattern), Validators.maxLength(6)]),],
      sale_period:[null],
      cancellation_policy:null,
    });

    tickets.controls['tickettype'].setValue("Early Bird", {onlySelf: true});
    tickets.controls['cancellation_policy'].setValue('Non-refundable', {onlySelf: true});
     
    return tickets;
  }

  public eventForm() {
    var eventForm = this.formBuilder.group({
      'eventTitle':[null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(500)])],
      'eventContact':[null, Validators.compose([Validators.required, Validators.pattern(this.mobnumPattern), Validators.maxLength(15)])],
      'eventPeriod':[null],
      'eventCity':[null,  Validators.compose([Validators.required, Validators.pattern(this.cityPattern)])],
      'eventAddress':[null, Validators.required],
      'eventCategory': null,
      'myFacility': '',
      'myfile':[null],
      'ticketype': null,
      'eventBanner': [null, Validators.required],
      'eventVenue':[null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(this.stringPattern)])],
      'eventDetail':[null, Validators.compose([Validators.required, Validators.minLength(30)])],
      'customTickets': this.formBuilder.array([]),
      'sellticket':[null, Validators.required]
    });

    // eventForm.controls['eventPeriod'].disable();
    return eventForm;
    // this.eventForm.controls['eventCategory'].setValue(this.default, {onlySelf: true});
  }


  public registerForm() {

    var registerForm = this.formBuilder.group({
      'userEmail':[null, Validators.email],
      'vendorEmail':[null, Validators.email],
      'userPassword':[null, Validators.compose([Validators.required])],
      'userConfirmPassword':[null, Validators.compose([Validators.required])],
      'username':[null, Validators.compose([Validators.required, Validators.pattern(this.stringPattern)])],
      'fullname':[null, Validators.compose([Validators.required, Validators.pattern(this.stringPattern)])],
      'address':[null, Validators.required],
      'organization': [null, Validators.required],
      'terms': [null, Validators.required],
    }, {validator: this.matchingPassword('userPassword','userConfirmPassword')}); 
    
    return registerForm;
  }

  public matchingPassword(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];
  
      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    }
  }

  public updateForm() {
    var updateForm = this.formBuilder.group({
      'eventTitle':[null, Validators.compose([Validators.required, Validators.minLength(4)])],
      'eventContact':[null, Validators.compose([Validators.required, Validators.pattern(this.mobnumPattern), Validators.maxLength(15)])],
      'eventPeriod':[null],
      'eventCity':[null,  Validators.compose([Validators.required, Validators.pattern(this.cityPattern)])],
      'eventAddress':[null, Validators.required],
      'eventCategory': null,
      'myFacility': '',
      'myfile':[null],
      'ticketype': null,
      'eventBanner': null,
      'eventVenue':[null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(this.stringPattern)])],
      'eventDetail':[null, Validators.compose([Validators.required, Validators.minLength(30)])],
      'customTickets': this.formBuilder.array([]),
    });

    // eventForm.controls['eventPeriod'].disable();
    return updateForm;
    // this.eventForm.controls['eventCategory'].setValue(this.default, {onlySelf: true});
  }


  public initializeControl(data, form) {

    form.controls['eventTitle'].setValue(data.event.event_title, {onlySelf: true});
    form.controls['eventTitle'].setValue(data.event.event_name);
    form.controls['eventAddress'].setValue(data.event.event_address);
    form.controls['eventCity'].setValue(data.event.event_city);
    form.controls['eventDetail'].setValue(data.event.event_description);
    form.controls['eventVenue'].setValue(data.event.event_venue);
    form.controls['eventContact'].setValue(data.event.event_contact);
    form.controls['eventPeriod'].setValue(data.event.event_start_date+' '+data.event.event_start_time+" - "+data.event.event_end_date+' '+data.event.event_end_time);
    form.controls['eventCategory'].setValue(data.event.event_category, {onlySelf: true});

    return form;
  }

  public initializeVendorControl(data, form) {

    form.controls['vendorName'].setValue(data.vendor.vendor_name);
    form.controls['vendorAdmin'].setValue(data.vendor.admin_name);
    form.controls['vendorContact'].setValue(data.vendor.contact);
    form.controls['vendorCity'].setValue(data.vendor.city);
    form.controls['vendorAddress'].setValue(data.vendor.address.address);
    form.controls['vendorCurrency'].setValue(data.vendor.currency, {onlySelf: true});

    return form;
  }

  public addToTIcketlist(newTicket) {
    var actionResult;
    var outcome;
    var value = newTicket.toUpperCase();
    var result = this.ticketTypes.indexOf(value);
    if (result != -1) {
       actionResult = "text-red";
       outcome = "ticket category already exist";
    }
    else {
      this.ticketTypes.push(value);
      actionResult = "text-success";
      outcome = "ticket category added successfully";

    }

    return [actionResult,outcome];
  }

  public createVendorForm() {

    var vendorForm = this.formBuilder.group({
      'vendorName':[null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(200)])],
      'vendorContact':[null, Validators.compose([Validators.required, Validators.pattern(this.mobnumPattern), Validators.maxLength(15)])],
      'vendorCity':[null,  Validators.compose([Validators.required, Validators.pattern(this.cityPattern)])],
      'vendorAdmin':[null,  Validators.compose([Validators.required, Validators.pattern(this.cityPattern)])],
      'vendorAddress':[null, Validators.required],
      'vendorCurrency': null,

    });

  
    vendorForm.controls['vendorName'].disable();
    vendorForm.controls['vendorCity'].disable();
    vendorForm.controls['vendorCurrency'].disable();
    vendorForm.controls['vendorAddress'].disable();

    return vendorForm;

  }

  public setdefaultMap(lat, lng) {

    var mapProp = {
      center: new google.maps.LatLng(lat,lng),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    return this.map;
  }


}
