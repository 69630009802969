import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { EventRestService } from '../../../Service/eventrest/event-rest.service';
import { GeneralService } from '../../../Service/generalservice/general.service';

@Component({
  selector: 'app-eventtab',
  templateUrl: './eventtab.component.html',
  styleUrls: ['./eventtab.component.css']
})
export class EventtabComponent implements OnInit {

  @Input() events: any;
  modalColor: string = null;
  serverMessage: string = null;

  constructor(private eventRest: EventRestService, private renderer: Renderer2,
    private el: ElementRef, private genService: GeneralService, ) { }

  ngOnInit() {
  }

  deleteEvent(event_id, element) {

    this.eventRest.deleteevent(event_id).subscribe(
      res => {
        this.serverMessage = res['success']
        this.modalColor = 'modal-success'
        let elem = element.path[5].querySelector('.label-warning')
        if (elem) {
          elem = elem as HTMLElement
          elem.innerText = 'Deleted'
          this.genService.launchModal()
          this.genService.addWarn(elem)
          // this.renderer.addClass(elem, 'label-danger')
          // this.renderer.removeClass(elem, 'label-warning')
          // elem.removeAllListeners('click')

        }

      },
      err => {
        this.serverMessage = err['error']
        this.modalColor = 'modal-danger'
        this.genService.launchModal()
      }
    );
  }


  approveevent(event_id, element) {

    this.eventRest.approveevent(event_id).subscribe(
      res => {
        this.serverMessage = res['success']
        this.modalColor = 'modal-success'
        element.target.innerText = 'Approved'
        this.genService.launchModal()
        this.genService.addSuccess(element)
        this.renderer.listen(element.target, 'click', (event) => {
          this.unapproveevent(event_id, event);
        })
      },
      err => {
        this.serverMessage = err['error']
        this.modalColor = 'modal-danger'
        this.genService.launchModal()
      }
    );
  }

  unapproveevent(event_id, element) {
    this.eventRest.unapproveevent(event_id).subscribe(
      res => {
        this.serverMessage = res['success']
        this.modalColor = 'modal-success'
        this.genService.launchModal()
        this.genService.addWarn(element)
        element.target.innerText = 'Unapproved'
        this.renderer.listen(element.target, 'click', (event) => {
          this.approveevent(event_id, event);
        })
      },
      err => {
        this.serverMessage = err['error']
        this.modalColor = 'modal-danger'
        this.genService.launchModal()
      }
    );
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }

}
