import { Component, OnInit, Input } from '@angular/core';
import { EventRestService } from '../../Service/eventrest/event-rest.service';
import { GeneralService } from '../../Service/generalservice/general.service';

@Component({
  selector: 'app-eventtable',
  templateUrl: './eventtable.component.html',
  styleUrls: ['./eventtable.component.css']
})
export class EventtableComponent implements OnInit {

  @Input() data: any = []; 

  constructor(private eventRest: EventRestService, private genService: GeneralService) {
  }
  ngOnInit() {
  }


  initialiseSale(event_id){
    this.eventRest.startSale(event_id).subscribe(
      res => {
          this.genService.showAlert(res.success,"alert-success")
      },
      err => {
        this.genService.showAlert(err.error['error'],"alert-danger")
      }
    )
  }

}
