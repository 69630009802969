import { Component, OnInit } from '@angular/core';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  detail: any  ;
  vendor_name: string = null;
  joined: string;
  vendor_id: string = null;
  queryVendor: string = null;
  userrole: string = null;
  constructor(private restService: RestService, private guardService: GuardService, private route: ActivatedRoute) { }

  ngOnInit() {
    

    this.guardService.canActivate();
    this.detail = JSON.parse(localStorage.getItem('currentUser'));
    this.joined = this.detail["user"].vendor.created;
    this.vendor_id = this.detail["user"].vendor.id;
    this.route.queryParams.subscribe(
      params => this.queryVendor = params['vendor']);
    this.userrole = this.detail.user.userrole.role_desc
    this.queryVendor = this.queryVendor==null?this.vendor_id:this.queryVendor
    this.getUserrole(this.queryVendor);
  }

  getUserrole(vendor) {
    this.userrole = null;
    if(vendor===undefined||vendor===null) return;
    let curData = JSON.parse(localStorage.getItem('currentUser'));
    this.restService.vendoruserrole(vendor).subscribe(
      res => {
        curData.user.vendor = res.user.vendor
        curData.user.userrole = res.user.userrole
        this.vendor_name = res.user.vendor.vendor_name
        this.vendor_id = res.user.vendor.id
        this.userrole = res.user.userrole.role_desc
        localStorage.setItem('currentUser', JSON.stringify(curData));
      },
      err => {
      });
  }

}
