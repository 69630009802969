
import { Ticket } from '../ticket/ticket.model';
import { Injectable } from "@angular/core";
import {Deserializable} from "../deserializable.model";

export class Eventcoupon implements Deserializable{
    coupon_id: number;
    event_id: number;
    coupon: string;
    status: number;
    coupon_limit: number;
    ticket_id: number;
    type: string;
    discount: number;
    constructor(){
    }
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    static create(coupon_id,event_id,coupon,status,coupon_limit,ticket_id,type,discount){
        return {coupon_id:coupon_id,event_id:event_id,coupon:coupon,status:status,coupon_limit:coupon_limit,ticket_id:ticket_id,type:type,discount:discount}
    }
}

