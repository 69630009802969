import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { } from 'googlemaps';
import { isEmpty } from 'rxjs/operators';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { GuardService } from '../../Service/guard/guard.service';
import { RestService } from '../../Service/restapi/rest.service';
import { EventRestService } from '../../Service/eventrest/event-rest.service';
import { GeneralService } from '../../Service/generalservice/general.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


const API_URL = environment.apiUrl;

@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.css']
})

export class AddeventComponent implements OnInit {
  end: any;
  start: any;
  marker: any;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('places') places: GooglePlaceDirective;
  public daterange: any = {};
  public facilities: any = [];
  public selectedFacility: any = [];
  eventForm: FormGroup;
  post: any;
  eventTitle: string = null;
  eventContact: string = null;
  ticketOption: any;
  selectedFile: File;
  imagePreview: string = "assets/images/photo1.png";
  defaultTicket: string = 'Early Bird';
  default: string = 'Party';
  serverMessage: string = "";
  modalColor: string = "";
  eventAddress: string = "";
  eventLong: any;
  eventLat: any;
  url: SafeResourceUrl;
  newTicket: string;
  outcome: string = '';
  mapUrl: any;
  netImage: any = "";
  actionResult: string = "";
  ticketPicked: string = 'Early Bird';
  eventStart: any;
  eventEnd: any;
  singleDateOptions: any;
  saleoptiondefault: string = '1';

  constructor(private generalService: GeneralService, private http: HttpClient,
    private restService: RestService, private guardService: GuardService, public dataService: EventserviceService, private router: Router, 
    private datePipe: DatePipe, private eventRest: EventRestService) {
    let tomorrow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.getFacilities();
    this.createForm();
    this.addCustomTicket();
    this.singleDateOptions = {
      locale: { format: 'YYYY-MM-DD' },
      timePicker: false,
      singleDatePicker: true,
      alwaysShowCalendars: false,
      minDate: tomorrow,
      drops: 'up'
    }

  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.type == 'image/png' || this.selectedFile.type == 'image/jpg' || this.selectedFile.type == 'image/jpeg') {
      var ch = new FileReader();
      ch.onload = (event: any) => {
        this.imagePreview = event.target.result;
      }
      ch.readAsDataURL(event.target.files[0]);
    }
    else {
      this.selectedFile = null;
    }
    this.eventForm.controls['eventBanner'].setValue(this.selectedFile);

  }

  createForm() {
    this.eventForm = this.dataService.eventForm();
    this.eventForm.controls['eventCategory'].setValue(this.default, { onlySelf: true });

  }

  get customTicketsArray(): FormArray {
    return this.eventForm.get('customTickets') as FormArray;
  }

  addCustomTicket() {

    let tickets = this.dataService.addCustomTicket();
    tickets.controls['tickettype'].setValue(this.defaultTicket, { onlySelf: true });
    // tickets.controls['cancellation_policy'].setValue('Non-refundable', { onlySelf: true });
    this.customTicketsArray.push(tickets);
  }

  removeCustomTicket(idx) {
    if (idx != 0) {
      this.customTicketsArray.removeAt(idx);
    }
  }


  createEvent(post) {
    if (this.eventStart === undefined || this.eventEnd === undefined || this.eventEnd === null || this.eventStart === null) {
      this.serverMessage = "You need to specify event periods";
      this.modalColor = "modal-danger";
      this.launchModal();
      return false;
    }
    else {
      for (var i = 0; i < post["customTickets"].length; i++) {
        var datatic = post["customTickets"][i]
        if (datatic.tickettype !== "Early Bird") {
          datatic.sale_period = this.eventEnd;
        } else if (datatic.tickettype === "Early Bird" && datatic.sale_period === null) {
          this.serverMessage = "You need to specify last sale date for Early Bird tickets.";
          this.modalColor = "modal-danger";
          this.launchModal();
          return false;
        }
      }
      var data_loc = [this.eventAddress, this.eventStart, this.eventEnd, this.eventLat, this.eventLong];
      this.eventRest.addEvent(post, data_loc, this.selectedFile, this.selectedFacility).subscribe(
        res => {
          this.serverMessage = "Event created succesfully but still pending approval.";
          this.modalColor = "modal-success";
          this.launchModal();
        },
        err => {
          this.serverMessage = err.error['error'];
          this.modalColor = "modal-danger";
          if (err.status === 401) {
            this.router.navigate(['./logout']);
          }
          else {
            this.launchModal();
            //show page with FORBIDDEN VIEW;
          }
        });
    }
  }

  setFacility(id) {
    var i = this.selectedFacility.indexOf(id);
    if (i != -1) {
      this.selectedFacility.splice(i, 1);
    }
    else {
      this.selectedFacility.push(id);
    }
  }

  checkExist(id) {

    var i = this.selectedFacility.indexOf(id);
    return i;
  }

  public handleAddressChange(address: Address) {
    if (typeof address === 'object') {
      this.eventLat = address.geometry.location.lat().toString();
      this.eventLong = address.geometry.location.lng().toString();
      this.eventAddress = address.formatted_address.toString();
      this.buildMap(this.eventLat, this.eventLong);

    }
  }

  public buildMap(lat, lon) {

    this.map.setCenter(new google.maps.LatLng(lat, lon));
    let location = new google.maps.LatLng(lat, lon);
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Event Location!'
      });
    }

    else {
      this.marker.setPosition(location);
    }

  }

  public getFacilities() {
    this.restService.allFacilities().subscribe(
      res => {
        this.facilities = res;
      },
      err => {
      }
    );

  }

  selectedDate(value: any, datepicker?: any) {
    let convStart = new Date(value.start._d);
    let convEnd = new Date(value.end._d);
    this.eventStart = convStart.toLocaleDateString() + " " + convStart.toLocaleTimeString();
    this.eventEnd = convEnd.toLocaleDateString() + " " + convEnd.toLocaleTimeString();
  }

  initialiseDate(value: any, datepicker?: any) {

    let convEnd = new Date(value.end._d);
    for (var i = 0; i < this.customTicketsArray.length; i++) {
      if (this.customTicketsArray.at(i).value.tickettype === "Early Bird") {
        this.customTicketsArray.at(i).value.sale_period = convEnd.toLocaleDateString()
        break;
      }
    }
  }

  addTolist(newTicket) {
    var value = this.dataService.addToTIcketlist(newTicket);
    this.actionResult = value[0];
    this.outcome = value[1];
  }

  reset() {
    this.actionResult = "";
    this.outcome = "";
  }

  ngOnInit() {
    this.guardService.canActivate();
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  launchModal() {
    this.openModal.nativeElement.click();
  }

  closeModal(color) {
    if (color === "modal-success") {
      this.router.navigateByUrl('event/manage');

    }
  }

  selectedTicket(idx) {
    return this.customTicketsArray.at(idx).get('tickettype').value;
  }

}
