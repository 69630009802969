import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GuardService } from '../../Service/guard/guard.service';
import { RestService } from '../../Service/restapi/rest.service';
import { EventRestService } from '../../Service/eventrest/event-rest.service';


const API_URL = environment.apiUrl;

@Component({
  selector: 'app-manageevents',
  templateUrl: './manageevents.component.html',
  styleUrls: ['./manageevents.component.css']
})
export class ManageeventsComponent {

  @Output() data: any = []; 
  isDataLoaded: boolean = false;
  isError: boolean = false;
  constructor(private http: HttpClient, private restService: RestService, private guardService: GuardService, 
    private router: Router, private eventRest: EventRestService) {

  }

  initialiseSale(event_id){
    this.eventRest.startSale(event_id).subscribe(
      res => {
          //this should restart the page or launch a modal.
      },
      err => {

      }
    )
  }

  getEvents() {
    this.restService.getevents().subscribe(
      res => {
        this.isDataLoaded = true;
        this.data = res;
      },
      err => {
        this.isDataLoaded = true;
        this.isError = true;
        if (err.status === 401) {
            this.router.navigate(['./logout']);
        }
      }
    );

  }

  ngOnInit() {
    this.guardService.canActivate();
    this.getEvents();
    // console.log(JSON.parse(localStorage.getItem('currentUser')));
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }

}
