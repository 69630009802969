export class Shortevent {
    event_name: string;
    event_start_date: string; 
    event_start_time: string; 
    event_end_date: string; 
    event_city: string;
    event_description: string; 
    approved: number;
    event_status: string;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
