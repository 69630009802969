import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { } from 'googlemaps';
import { isEmpty } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { GuardService } from '../../Service/guard/guard.service';
import { RestService } from '../../Service/restapi/rest.service';
import { EventRestService } from '../../Service/eventrest/event-rest.service';
import { Router } from '@angular/router';

const API_URL = environment.apiUrl;

@Component({
  selector: 'app-editevent',
  templateUrl: './editevent.component.html',
  styleUrls: ['./editevent.component.css']
})
export class EditeventComponent implements OnInit {

  end: any;
  start: any;
  marker: any;
  selectedFile: File;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  @ViewChild('places') places: GooglePlaceDirective;
  @ViewChild('openModal') openModal: ElementRef;
  data: any = [];
  post: any;
  serverMessage: string = "";
  modalColor: string = "";
  errorMessage: string = "";
  eventForm: FormGroup;
  imagePreview: string = "assets/images/photo1.png";
  public selectedFacility: any = [];
  Tickets: any = [];
  eventStart: any;
  eventEnd: any;
  eventAddress: string;
  eventLong: any;
  eventLat: any;
  eventId: any;
  public daterange: any = {};
  oldBanner: any;


  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, public dataService: EventserviceService,
    private http: HttpClient, private sanitizer: DomSanitizer, private eventRest: EventRestService, 
    private guardService: GuardService, private router: Router) {
    this.updateForm()

  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.type == 'image/png' || this.selectedFile.type == 'image/jpg') {
      var ch = new FileReader();
      ch.onload = (event: any) => {
        this.imagePreview = event.target.result;
      }
      ch.readAsDataURL(event.target.files[0]);
    }
    else {
      this.selectedFile = null;
    }

  }

  ngOnInit() {
    this.guardService.canActivate();
    this.initializeMap();
    this.route.queryParams.subscribe(
      params => {
        this.eventId = params['event'];
      })
    this.getData();
  }

  public initializeMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  public selectedDate(value: any, datepicker?: any) {
    this.eventStart = value.start;
    this.eventEnd = value.end;
    let convStart = new Date(value.start._d);
    let convEnd = new Date(value.end._d);

    this.eventStart = convStart.toLocaleDateString() + " " + convStart.toLocaleTimeString();
    this.eventEnd = convEnd.toLocaleDateString() + " " + convEnd.toLocaleTimeString();
  }


  updateForm() {

    this.eventForm = this.dataService.updateForm();
    // this.eventForm.controls['eventPeriod'].disable();
  }

  getFacility(selectedFacility, allFacilities) {
    this.selectedFacility = selectedFacility.map(function (a) { return a["facility_id"]; });

  }

  setFacility(id) {
    var i = this.selectedFacility.indexOf(id);
    if (i != -1) {
      this.selectedFacility.splice(i, 1);
    }
    else {
      this.selectedFacility.push(id);
    }
  }

  checkExist(id) {
    var i = this.selectedFacility.indexOf(id);
    return i;
  }

  getData() {

    this.eventRest.geteventDetail().subscribe(
      res => {
        
        this.data = res;
        this.eventForm = this.dataService.initializeControl(this.data, this.eventForm);
        this.imagePreview = "https://engine.ticketvaults.com/eventbanner/" + this.data.event.event_image;
        this.oldBanner = this.data.event.event_image;   
        this.createTicket(this.data.event.ticket_category);
        this.buildMap(this.data.event.event_lon, this.data.event.event_lat);
        this.eventStart = this.data.event.event_start_date+' '+this.data.event.event_start_time
        this.eventEnd = this.data.event.event_end_date+' '+this.data.event.event_end_time
        this.getFacility(this.data.event.eventfacility, this.data.facility);
      },
      err => {
        if (err.status === 401) {
          this.router.navigate(['./logout']);
        }
        else {
          this.serverMessage = err.error['error'];        
          this.modalColor = "modal-danger";
          this.launchModal();
          //showView with forbidden;
        }
      });
  }


  createTicket(createdTickets) {
    for (var i = 0; i < createdTickets.length; i++) {
      var result = this.dataService.addCustomTicket();
      result.controls['tickettype'].setValue(createdTickets[i].type, { onlySelf: true });
      result.controls['cancellation_policy'].setValue(createdTickets[i].cancellation_policy, { onlySelf: true });
      result.controls['ticket_amt'].setValue(createdTickets[i].ticket_price);
      result.controls['ticket_qty'].setValue(createdTickets[i].ticket_qty);
      result.controls['tickettype'].disable();
      result.controls['ticket_amt'].disable();
      result.controls['cancellation_policy'].disable();
      result.controls['ticket_qty'].disable();
      this.customTicketsArray.push(result);
    }
  }

  updateEvent(post) {
    var data = [this.eventAddress, this.eventStart, this.eventEnd, this.eventLat, this.eventLong];
    this.eventRest.updateEvent(post, data, this.selectedFile, this.selectedFacility, this.eventId, this.oldBanner).subscribe(
      res => {

        this.serverMessage = "Event updated succesfully but still pending approval.";
        this.modalColor = "modal-success";
        this.launchModal();
      },

      err => {
        if (err.status === 401) {
          this.router.navigate(['./logout']);
        }
        else {
          this.serverMessage = err.error['error'];
          this.modalColor = "modal-danger";
          this.launchModal();
          //show that user is not allowed to post for this event.
        }
      });
  }

  get customTicketsArray(): FormArray {
    return this.eventForm.get('customTickets') as FormArray;
  }

  launchModal() {
    this.openModal.nativeElement.click();
    // this.router.navigate(['./event/manage']);
  }

  goback() {
    // this.router.navigate(['./event/manage']);
  }

  public buildMap(lat, lon) {
    if ((lat !== null && lon !== null) || (lat !== undefined && lon !== undefined)) {
      this.eventLat = lat;
      this.eventLong = lon;
      this.map.setCenter(new google.maps.LatLng(lat, lon));
      let location = new google.maps.LatLng(lat, lon);
      this.map.panTo(location);
      if (!this.marker) {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map,
          title: 'Event Location!'
        });
      }

      else {
        this.marker.setPosition(location);
      }
    }
  }

  public handleAddressChange(address: Address) 
  { 
      if (typeof address === 'object') {

        this.eventLat = address.geometry.location.lat().toString();
        this.eventLong = address.geometry.location.lng().toString();
        this.eventAddress = address.formatted_address;
        this.buildMap(this.eventLat, this.eventLong);
        
      }

  }
}
