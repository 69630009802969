import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutes } from './app.routing';
import { RouterModule} from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {QuillModule} from 'ngx-quill';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Daterangepicker } from 'ng2-daterangepicker';
import {DatePipe} from '@angular/common';
import { BootstrapAlertModule } from 'ngx-bootstrap-alert';
import { AvatarModule } from 'ngx-avatar';
import { Ng5SliderModule } from 'ng5-slider';
import { OptioncardDirective } from './container/Directives/optioncard.directive';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { SidebarComponent } from './Shared/sidebar/sidebar.component';
import { NavbarComponent } from './container/navbar/navbar.component';
import { FooterComponent } from './container/footer/footer.component';
import { InvoiceComponent } from './component/invoice/invoice.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ManageeventsComponent } from './component/manageevents/manageevents.component';
import { VieweventComponent } from './component/viewevent/viewevent.component';
import { AddeventComponent } from './component/addevent/addevent.component';
import { VendorLoginComponent } from './component/vendor-login/vendor-login.component';
import { DashboardComponent } from './container/dashboard/dashboard.component';
import {EventStatisticComponent } from './component/event-statistic/event-statistic.component';
import { EditprofileComponent } from './component/editprofile/editprofile.component';
import { EditeventComponent } from './component/editevent/editevent.component';
import { EventserviceService } from './Service/eventservice/eventservice.service';
import { RestService } from './Service/restapi/rest.service';
import { EventRestService } from './Service/eventrest/event-rest.service';
import { GeneralService } from './Service/generalservice/general.service';
import { GuardService } from './Service/guard/guard.service';
import { ErrorComponent } from './container/error/error.component';
import { PreloadComponent } from './container/preload/preload.component';
import { RegisterComponent } from './component/register/register.component';
import { ManagecouponComponent } from './component/managecoupon/managecoupon.component';
import { ManageuserComponent } from './component/manageuser/manageuser.component';
import { MasterboardComponent } from './component/masterboard/masterboard.component';
import { AlertComponent } from './container/alert/alert.component';
import { EventtableComponent } from './container/eventtable/eventtable.component';
import { SearchPipe } from './Shared/PipeElement/search/search.pipe';
import { HeaderComponent } from './container/header/header.component';
import { ModalComponent } from './container/modal/modal.component';
import { EventtabComponent } from './container/tabs/eventtab/eventtab.component';
import { TickettabComponent } from './container/tabs/tickettab/tickettab.component';
import { VendortabComponent } from './container/tabs/vendortab/vendortab.component';
import { StatisticsboxComponent } from './container/statisticsbox/statisticsbox.component';
import { StatisticstableComponent } from './container/statisticstable/statisticstable.component';
import { TimelineComponent } from './container/timeline/timeline.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    InvoiceComponent,
    ProfileComponent,
    ManageeventsComponent,
    VieweventComponent,
    AddeventComponent,
    VendorLoginComponent,
    EventStatisticComponent,
    DashboardComponent,
    EditprofileComponent,
    EditeventComponent,
    ErrorComponent,
    PreloadComponent,
    RegisterComponent,
    ManagecouponComponent,
    ManageuserComponent,
    MasterboardComponent,
    OptioncardDirective,
    AlertComponent,
    EventtableComponent,
    SearchPipe,
    HeaderComponent,
    ModalComponent,
    EventtabComponent,
    TickettabComponent,
    VendortabComponent,
    StatisticsboxComponent,
    StatisticstableComponent,
    TimelineComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QuillModule,
    GooglePlaceModule,
    Daterangepicker,
    AvatarModule,
    Ng5SliderModule,
    BootstrapAlertModule
  ],
  providers: [EventserviceService, RestService, GuardService, DatePipe,EventRestService,GeneralService],
  bootstrap: [AppComponent]
})
export class AppModule { }
