import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statisticstable',
  templateUrl: './statisticstable.component.html',
  styleUrls: ['./statisticstable.component.css']
})
export class StatisticstableComponent implements OnInit {

  @Input() buyerlist: any;
  constructor() { }

  ngOnInit() {
  }

}
