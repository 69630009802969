import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Managecoupon } from '../../Model/managecoupon/managecoupon.model';
import { Manageuser } from '../../Model/manageuser/manageuser.model';
import { User } from '../../Model/user/user.model';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '../generalservice/general.service';
const API_URL = environment.apiUrl;

@Injectable()

export class EventRestService {

  private dashboardDataUrl = API_URL + "/vendor/";
  private detailsUrl = API_URL + "/vendor/getvendordetails";
  private eventsUrl = API_URL + "/event/";
  private vendorId: String = null;
  private eventId: String = null;
  private account: any = null;
  constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router,private generalService:GeneralService) {
    this.route.queryParams.subscribe(
      params => {
        this.vendorId = params['vendor'];
        this.eventId = params['event'];
      }
    )

    this.account = JSON.parse(localStorage.getItem('currentUser'));
    if(this.account == null) this.logout();
  }

  logout(){

    localStorage.removeItem('currentUser');
    this.router.navigate(['./logout']);
  }

  getevents(): Observable<any> {

    let params = new HttpParams();
    let url = this.dashboardDataUrl + this.vendorId + '/events';
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
    .map(response => {
      return response
    })
    .catch(error => {
      return this.generalService.errorHandling(error);
    })
  }

  getTicket_stats(): Observable<any> {
    
    let url = this.eventsUrl + this.eventId + '/statistics/' + this.vendorId;
    return this.http
      .get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  geteventcoupon(): Observable<Managecoupon> {

    let url = this.eventsUrl + this.eventId + '/managecoupon/' + this.vendorId;
    return this.http
      .get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } }).pipe(
      map((data: any) => new Managecoupon().deserialize(data)))
      .catch(error => {
        return this.generalService.errorHandling(error);
      });
  }

  startSale(event_id) {

    let url = this.eventsUrl + event_id + '/startsale/' + this.vendorId;
    return this.http
      .patch(url, null, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  approveevent(event_id) {

    let url = this.eventsUrl+'approveevent/'+event_id;
    return this.http
      .patch(url, null,{ headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  deleteevent(event_id) {

    let url = this.eventsUrl+'deleteevent/'+event_id;
    return this.http
      .patch(url, null,{ headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  unapproveevent(event_id) {
    
    let url = this.eventsUrl+'unapproveevent/'+event_id;
    return this.http
      .patch(url, null, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  addEventCoupon(couponobject): Observable<any> {
    
    if (typeof couponobject === 'object') {
      const formData = new FormData();
      let url = this.eventsUrl + this.eventId + '/addcoupon/' + this.vendorId;
      Object.keys(couponobject).forEach(key => formData.append(key, couponobject[key]));
      return this.http
        .post(url, formData, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
        .map(response => {
          return response
        })
        .catch(error => {
          return this.generalService.errorHandling(error);
        })
    }
  }

  addEvent(post, data, selectedFile, selectedFacility): Observable<any> {

    if (data[0] != null) {
      post.eventAddress = data[0];
    }

    let url = this.eventsUrl + 'createevent/' + this.vendorId;
    const uploadData = new FormData();
    uploadData.append('eventTitle', post.eventTitle);
    uploadData.append('eventBanner', selectedFile, selectedFile.name);
    uploadData.append('eventCategory', post.eventCategory);
    uploadData.append('eventContact', post.eventContact);
    uploadData.append('eventContact', post.eventContact);
    uploadData.append('startSale', post.sellticket);
    uploadData.append('transfercharges', post.transfer);
    uploadData.append('eventStart', data[1]);
    uploadData.append('eventEnd', data[2]);
    uploadData.append('eventAddress', post.eventAddress);
    uploadData.append('eventCity', post.eventCity);
    uploadData.append('eventVenue', post.eventVenue);
    uploadData.append('eventCategory', post.eventCategory);
    uploadData.append('eventDetail', post.eventDetail);
    uploadData.append('eventLat', data[3]);
    uploadData.append('eventLong', data[4]);
    uploadData.append('customTickets', JSON.stringify(post.customTickets));
    uploadData.append('eventFacilities', JSON.stringify(selectedFacility));

    return this.http
      .post(url, uploadData, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  updateEvent(post, data, selectedFile, selectedFacility, eventId, oldBanner): Observable<any> {

    if (data[0] != null) {
      post.eventAddress = data[0];
    }
    let url = this.eventsUrl + this.eventId + '/updateevent/' + this.vendorId;
    const uploadData = new FormData();
    uploadData.append('eventTitle', post.eventTitle);
    if (selectedFile !== null && selectedFile !== undefined && selectedFile !== "") {
      uploadData.append('eventBanner', selectedFile, selectedFile.name);
    } else { uploadData.append('eventBanner', "empty"); }

    uploadData.append('eventCategory', post.eventCategory);

    uploadData.append('eventContact', post.eventContact);
    uploadData.append('eventStart', data[1]);
    uploadData.append('eventEnd', data[2]);
    uploadData.append('eventLat', data[3]);
    uploadData.append('eventLong', data[4]);
    uploadData.append('eventAddress', post.eventAddress);
    uploadData.append('eventVenue', post.eventVenue);
    uploadData.append('eventCity', post.eventCity);
    uploadData.append('eventCategory', post.eventCategory);
    uploadData.append('eventContact', post.eventContact);
    uploadData.append('eventDetail', post.eventDetail);
    uploadData.append('oldBanner', oldBanner)
    uploadData.append('eventId', eventId);
    uploadData.append('eventFacilities', JSON.stringify(selectedFacility));
    return this.http
      .post(url, uploadData, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  geteventDetail(): Observable<any> {

    let url = this.eventsUrl+this.eventId+'/long/'+this.vendorId;
    return this.http
      .get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }
}
