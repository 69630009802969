import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/map';


const API_URL = environment.apiUrl;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent {
  

    data: any = [];

    private detailsUrl = API_URL+"/api/getvendordetails";

    constructor(private http: HttpClient) {

      this.getdetails();
      this.getData();
    }
  
    getData() {
      return this.http.get(this.detailsUrl).subscribe(data => {
        this.data = data;
      })
    }

    getdetails(){
      
    }
  }

// export class HomeComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }


// }