import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { RestService } from '../../Service/restapi/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardService } from '../../Service/guard/guard.service';
import { Manageuser } from '../../Model/manageuser/manageuser.model';
import { User } from '../../Model/user/user.model';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { BootstrapAlertService, BootstrapAlert } from 'ngx-bootstrap-alert';


@Component({
  selector: 'app-manageuser',
  templateUrl: './manageuser.component.html',
  styleUrls: ['./manageuser.component.css']
})
export class ManageuserComponent implements OnInit {

  data: User[];
  vendor_id: string;
  isDataLoaded: boolean = false;
  modalColor: string = 'warning';
  serverMessage:string = "";
  userForm: FormGroup;
  updatepassForm: FormGroup;
  alert: any = {"message":'',"alerttype":''}

  constructor(private formBuilder: FormBuilder, private restService: RestService, private router: Router, 
    private guardService: GuardService, private dataService: EventserviceService,private route: ActivatedRoute,
    private bootstrapAlertService:BootstrapAlertService) { }

  ngOnInit() {
    this.guardService.canActivate();
    this.vendor_id = this.route.snapshot.paramMap.get('vendor');
    this.getUserlist(this.vendor_id);
  }

  private getUserlist(id){

    this.restService.getvendoruser().subscribe(
      res => {
        this.createForm();
        this.data = res.user;
        this.isDataLoaded = true;
      },
      err => {

      });
  }

  updatepassword = () => {
    this.restService.updatepassword(this.updatepassForm.value).subscribe(
      res => {
        this.updatepassForm.reset();
        this.showAlert(res.success, 'alert-success');
      },
      err => {
        this.showAlert(err.error.error, 'alert-danger');
      }
    )
  }

  private addnewuser(){
      this.restService.addNewUser(this.userForm.value).subscribe(
        res => {
          this.data = res.users
          this.userForm.reset();
          this.showAlert(res.success, 'alert-success');
        },
        err => {
          this.showAlert(err.error.error, 'alert-danger');
        }
      )
  }


  showAlert = (msg,ttype) => {
    var alert = new BootstrapAlert(msg, ttype);
    alert.timeoutInMiliSeconds = 5000;
    this.bootstrapAlertService.alert(alert);
  }

  private createForm() {
    this.userForm = this.formBuilder.group({
      'username': [null, Validators.required],
      'email': [null, Validators.email],
      'name': [null, Validators.compose([Validators.required,Validators.pattern(this.dataService.stringPattern)])],
      'phonenumber': [null, Validators.compose([Validators.required,Validators.pattern(this.dataService.mobnumPattern)])]
    });

    this.updatepassForm = this.formBuilder.group({
      'password':[null, Validators.compose([Validators.required])],
      'confirmpassword':[null, Validators.compose([Validators.required])],
    }, {validator: this.dataService.matchingPassword('password','confirmpassword')}); 
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }

}
