import {Deserializable} from "../deserializable.model";
import {TicketCategory} from "../ticketcategory/ticket-category.model";
import {Eventcoupon} from "../eventcoupon/eventcoupon.model";
import {Shortevent} from "../shortevent/shortevent.model";

export class Managecoupon implements Deserializable{
    ticketcategory: TicketCategory[];
    eventcoupon: Eventcoupon[];
    event: Shortevent;
    deserialize(input: any) {
        Object.assign(this, input);
        this.ticketcategory = input.ticketcategory.map((ticketcategory: TicketCategory) => new TicketCategory().deserialize(ticketcategory))
        this.eventcoupon = input.eventcoupon.map((eventcoupon: Eventcoupon) => new Eventcoupon().deserialize(eventcoupon))
        this.event = new Shortevent().deserialize(input.event);
        return this;
      }
}
