import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HomeComponent } from './component/home/home.component';
import { InvoiceComponent} from './component/invoice/invoice.component';
import {ProfileComponent} from './component/profile/profile.component';
import { ManageeventsComponent } from './component/manageevents/manageevents.component';
import { VieweventComponent } from './component/viewevent/viewevent.component';
import { AddeventComponent } from './component/addevent/addevent.component';
import { VendorLoginComponent } from './component/vendor-login/vendor-login.component';
import { DashboardComponent } from './container/dashboard/dashboard.component';
import {EventStatisticComponent } from './component/event-statistic/event-statistic.component';
import { EditprofileComponent } from './component/editprofile/editprofile.component';
import { EditeventComponent } from './component/editevent/editevent.component';
import { RegisterComponent } from './component/register/register.component';
import { ManagecouponComponent } from './component/managecoupon/managecoupon.component';
import { ManageuserComponent } from './component/manageuser/manageuser.component';
import { MasterboardComponent } from './component/masterboard/masterboard.component';



export const AppRoutes: Routes = [
    { path: '', component: VendorLoginComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'login', component: VendorLoginComponent},
    { path: 'logout', component: VendorLoginComponent},
    { path: 'invoice', component: InvoiceComponent},
    { path: 'profile', component: ProfileComponent},
    { path: 'event/editevent', component: EditeventComponent},
    { path: 'event/manage', component:ManageeventsComponent},
    { path: 'event/add', component:AddeventComponent},
    { path: 'event/view', component:VieweventComponent},
    { path: 'event/statistics', component:EventStatisticComponent},
    { path: 'editprofile', component:EditprofileComponent},
    { path: 'event/managecoupon', component: ManagecouponComponent},
    { path: 'register', component:RegisterComponent},
    { path: 'manageuser', component:ManageuserComponent},
    { path: 'masterboard', component:MasterboardComponent},
    { path: '**', redirectTo: 'login' }
]
