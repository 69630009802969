import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  data: any = [];
  @Output() events: any = []; 
  isDataLoaded: boolean = false;
  isError: boolean = false;
  vendor: String = null;

  constructor(private http: HttpClient, private route: ActivatedRoute, private locat: Location, private restService: RestService, private guardService: GuardService, private router: Router) {      
  
  }
    
  getData() {
    
    this.restService.dashboardData(this.vendor).subscribe(
       res => {
        this.data = res;
        this.events = res["events"];
        this.isDataLoaded = true;
       },
        err => {
          this.isDataLoaded = true;
          this.isError = true;
          if(err.status === 401){
            this.router.navigate(['./logout']);
          }
          // this.router.navigate(['./logout']);
       }
     );
  
  }
  
  ngOnInit() {
    this.guardService.canActivate();
    this.route.queryParams.subscribe(
      params => this.vendor = params['vendor']);
    if(this.vendor==undefined){
      this.isDataLoaded = true;
      this.isError = true;
    }else this.getData()
  }

  load () {
    // this.locat.reload();
  }
}
