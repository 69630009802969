import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Managecoupon } from '../../Model/managecoupon/managecoupon.model';
import { Manageuser } from '../../Model/manageuser/manageuser.model';
import { User } from '../../Model/user/user.model';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardService } from '../guard/guard.service';
import { GeneralService } from '../generalservice/general.service';

const API_URL = environment.apiUrl;

@Injectable()

export class RestService {


  private dashboardDataUrl = API_URL + "/vendor/";
  private facilityUrl = API_URL + "/event/facilities";

  private vendorId: String = null;
  private account: any = null;
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private generalService: GeneralService) {
    this.route.queryParams.subscribe(
      params => this.vendorId = params['vendor']);
    this.account = JSON.parse(localStorage.getItem('currentUser'));
    if (this.account == null) this.logout();
    console.log(console.log(this.vendorId))
  }

  logout() {

    localStorage.removeItem('currentUser');
    this.router.navigate(['./logout']);
  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem('currentUser'));
    if (this.account == null) this.logout();
  }

  vendoruserrole(vendor_id): Observable<any> {
    let url = this.dashboardDataUrl + vendor_id + '/vendoruserrole';
    return this.http.get(url, { headers: { Authorization: "Bearer " + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  dashboardData(vendor_id): Observable<any> {
    this.account = JSON.parse(localStorage.getItem('currentUser'));
    let url = this.dashboardDataUrl + vendor_id + '/dashboard';
    return this.http.get(url, { headers: { Authorization: "Bearer " + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  allFacilities(): Observable<any> {

    return this.http
      .get(this.facilityUrl, { headers: { Authorization: "Bearer " + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  masterboardData(): Observable<any> {

    let url = this.dashboardDataUrl + '/masterboard';
    return this.http.get(url, { headers: { Authorization: "Bearer " + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  vendorData(): Observable<any> {

    let url = this.dashboardDataUrl+this.vendorId;
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  unverifyvendor(vendor_id) {

    let url = this.dashboardDataUrl + '/unverifyvendor/' + vendor_id;
    return this.http
      .patch(url, null, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  verifyvendor(vendor_id) {

    let url = this.dashboardDataUrl + '/verifyvendor/' + vendor_id;
    return this.http
      .patch(url, null, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  getevents(): Observable<any> {

    let params = new HttpParams();
    let url = this.dashboardDataUrl + this.vendorId + '/events';
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  getInvoice(): Observable<any> {

    let params = new HttpParams();
    let url = this.dashboardDataUrl + this.vendorId + '/invoice';
    return this.http.get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  getvendoruser(): Observable<Manageuser> {

    let url = `${this.dashboardDataUrl}${this.vendorId}/manageuser`
    console.log(url)
    return this.http
      .get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } }).pipe(
        map((data: any) => new Manageuser().deserialize(data)))
      .catch(error => {
        return this.generalService.errorHandling(error);
      });
  }

  updateVendor(post): Observable<any> {

    const Data = new FormData();
    let url = this.dashboardDataUrl + this.vendorId + '/updateprofile';
    Data.append('vendorAdmin', post.vendorAdmin);
    Data.append('vendorContact', post.vendorContact);
    return this.http
      .post(url, Data, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  addBankDetails(post): Observable<any> {

    const Data = new FormData();
    let url = this.dashboardDataUrl + this.vendorId + '/addbankdetails';
    Data.append('bank_name', post.bank);
    Data.append('account_no', post.account_no);
    Data.append('iban_no', post.iban_no);
    return this.http
      .post(url, Data, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  updatepassword(post): Observable<any> {

    const Data = new FormData();;
    let url = `${this.dashboardDataUrl}${this.vendorId}/updatepassword`;
    Data.append('password', post.password);
    return this.http
      .post(url, Data, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  addNewUser(post): Observable<any> {

    const Data = new FormData();;
    let url = `${this.dashboardDataUrl}${this.vendorId}/addnewuser`;
    Data.append('name', post.name);
    Data.append('username', post.username);
    Data.append('phonenumber', post.phonenumber);
    Data.append('email', post.email);
    return this.http
      .post(url, Data, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

  getvendor(): Observable<any> {

    let url = this.dashboardDataUrl + this.vendorId;
    return this.http
      .get(url, { headers: { Authorization: 'Bearer ' + this.account["token"] } })
      .map(response => {
        return response
      })
      .catch(error => {
        return this.generalService.errorHandling(error);
      })
  }

}
