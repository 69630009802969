import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {SecurityContext } from '@angular/core';
import { BootstrapAlertService, BootstrapAlert } from 'ngx-bootstrap-alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {


  @Input() alerttype: string;
  @Input() message: string;
  isOpen: boolean = true;
  constructor(public sanitizer: DomSanitizer,private bootstrapAlertService:BootstrapAlertService) {

  }

  ngOnInit() {
  }

}
