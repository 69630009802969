import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statisticsbox',
  templateUrl: './statisticsbox.component.html',
  styleUrls: ['./statisticsbox.component.css']
})
export class StatisticsboxComponent implements OnInit {

  @Input() buyerlist: any;
  @Input() ticketstatistics: any;
  
  constructor() { }

  ngOnInit() {
  }

}
