import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { GeneralService } from '../../Service/generalservice/general.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


export class RegisterComponent implements OnInit {

  @ViewChild('openModal') openModal:ElementRef;
  registerForm: FormGroup;
  modalMessage: string = "";
  modalColor: string = "modal-danger";
  reloadUrl: string = "";

  constructor(private formBuilder: FormBuilder, private router: Router, private dataService: EventserviceService, private generalService: GeneralService) {
    this.createForm()
   }

  ngOnInit() {
  }

  createForm() {
    this.registerForm = this.dataService.registerForm();
  }

  createVendor(post) {
      if(post.userPassword !== post.userConfirmPassword) {
        this.modalColor = "modal-danger";
        this.modalMessage = "Password must be the same";
      }else {
        this.generalService.addVendor(post).subscribe(
          res => {

            this.modalMessage = res["success"];
            this.modalColor = "modal-success"; 
            this.launchModal();  
            this.reloadUrl = "login";
          },
           err => {
  
            this.modalMessage = err.error["error"];
            this.modalColor = "modal-danger"; 
            this.launchModal();  
          }
        );
      }
  }


  launchModal(){
    this.openModal.nativeElement.click();
  }

  closeModal() {
    if(this.reloadUrl !== "") {
      this.router.navigate([this.reloadUrl]);
    }
  }
}
