import {Deserializable} from "../deserializable.model";
import { Injectable } from "@angular/core";

export class User implements Deserializable{
    _id: string;
    name: string;
    phonenumber: string;
    fullname: string;
    created: string;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
