import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HomeComponent} from '../home/home.component';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  // providers:[HomeComponent]
})
export class ProfileComponent implements OnInit {
  
  data: any = [];
  isDataLoaded: boolean = false;
  isError: boolean = false;

  constructor(private http: HttpClient, private restService: RestService, private guardService: GuardService,private router: Router) { 
    this.getData();
  }


  getData() {
    this.restService.vendorData().subscribe(
       res => {
        this.data = res;
        this.isDataLoaded = true;
       },
        err => {
          this.isDataLoaded = true;
          this.isError = true;
          if(err.status === 401) {
            this.router.navigate(['./logout']);
         }
      }
    );
  
  }

  ngOnInit() {
    this.guardService.canActivate();
  }


}
