import { Component, OnInit } from '@angular/core';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  detail: any  ;
  vendor_name: string;
  joined: string;

  constructor(private restService: RestService, private guardService: GuardService) { }

  ngOnInit() {
    
    this.guardService.canActivate();
    this.detail = JSON.parse(localStorage.getItem('currentUser'));
    this.vendor_name = this.detail["user"].username;
    this.joined = this.detail["user"].created;

  }

}
