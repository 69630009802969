import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { RequestOptions } from '@angular/http';
import { GuardService } from '../../Service/guard/guard.service';
import { EventRestService } from '../../Service/eventrest/event-rest.service';
import { environment } from '../../../environments/environment';


const API_URL = environment.apiUrl;


@Component({
  selector: 'app-viewevent',
  templateUrl: './viewevent.component.html',
  styleUrls: ['./viewevent.component.css']
})
export class VieweventComponent implements OnInit {

  data: any = [];
  isDataLoaded: boolean = false;
  isError: boolean = false;
  imageSrc: string = "assets/images/5.jpg";

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private eventRest: EventRestService, private guardService: GuardService) {

  }

  ngOnInit() {
    this.guardService.canActivate();
    this.getevent_details();
  }

  getevent_details() {
    this.eventRest.geteventDetail().subscribe(
      res => {
        this.isDataLoaded = true;
        this.data = res;
        if(this.data.event.event_image !== "") {
          this.imageSrc = API_URL+"/eventbanner/"+this.data.event.event_image;
        }
      },
      err => {   
            this.isDataLoaded = true;
            this.isError = true;
            if(err.status === 401){
              this.router.navigate(['./logout']);
            }
      }
    );

  }
}
