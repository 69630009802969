import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { } from 'googlemaps';
import { isEmpty } from 'rxjs/operators';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { GuardService } from '../../Service/guard/guard.service';
import { RestService } from '../../Service/restapi/rest.service';
import { Router } from '@angular/router';


// const API_URL = environment.apiUrl;

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {

  end: any;
  start: any;
  marker: any; 
  @ViewChild('gmap') gmapElement: any = " ";
  map: google.maps.Map;
  @ViewChild('openModal') openModal:ElementRef;
  @ViewChild('places') places:GooglePlaceDirective;
  data: any = [];
  post: any;
  serverMessage: string = "";
  modalColor: string = "";
  errorMessage: string = "";
  vendorAddress: string = "";
  vendorLong: any;
  vendorLat: any;
  url: SafeResourceUrl;
  mapUrl: any;
  vendorForm: FormGroup;
  isDataLoaded: boolean = false;
  isError: boolean = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private sanitizer: DomSanitizer,
    private restService: RestService, private guardService: GuardService, public dataService: EventserviceService, private router: Router) { 

    this.getData();
    this.createForm();
  
  }

  ngOnInit() {
    this.guardService.canActivate();
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }
  
  createForm() {
    this.vendorForm = this.dataService.createVendorForm(); 
  }

  public handleAddressChange(address: Address) 
  { 

    // if (address != null) {
      if (typeof address === 'object') {

        this.vendorLat = address.geometry.location.lat().toString();
        this.vendorLong = address.geometry.location.lng().toString();
        console.log(address.formatted_address);
        this.vendorAddress = address.formatted_address;
        this.buildMap(this.vendorLat, this.vendorLong);
        
      }
      
      // this will get the country
      // if (address.address_components.length > 0) {
      //    var len = address.address_components.length - 1;
      //    console.log(address.address_components[len].long_name);
      // }
    // }
  }

  public buildMap(lat,lon) {

    this.map.setCenter(new google.maps.LatLng(lat, lon));    
    let location = new google.maps.LatLng(lat, lon);
    this.map.panTo(location);
    
    if (!this.marker) {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map,
          title: 'Vendor Location!'
        });
      }
      
      else {
        this.marker.setPosition(location);
      }

  }


 public getData() {
  this.restService.getvendor().subscribe(
     res => {
      this.isDataLoaded = true;
      this.data = res;
      this.vendorForm = this.dataService.initializeVendorControl(this.data, this.vendorForm);
      this.buildMap(this.data.vendor.address.latitude,this.data.vendor.address.longitude);
     },
      err => {

        this.isDataLoaded = true;
        this.isError = true;
        if (err.status === 401 ) {
          // this.router.navigate(['./logout']);
        }else {
          this.serverMessage = err.error['error'];
          this.modalColor = "modal-danger";
          this.launchModal();
        }
     }
   );

}
 updateProfile(post) {
  this.restService.updateVendor(post).subscribe(
    res => {
      
      this.serverMessage = "Profile details updated successfully. Changes might take few seconds to reflect. ";
      this.errorMessage = "";
      this.modalColor = "modal-success";
      this.launchModal();
    },

    err => {   
      if(err.status === 500 || err.status === 404) {
        this.errorMessage = err.error['error'];
        this.modalColor = "modal-danger";
        this.launchModal();
      } else {
        // this.router.navigate(['./logout']);
      }
    
    });

}

 launchModal(){

  this.openModal.nativeElement.click();
 }



}
