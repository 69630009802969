import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { RestService } from '../../Service/restapi/rest.service';
import { GeneralService } from '../../Service/generalservice/general.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  accountForm: FormGroup;
  data: any = [];
  result: any = [];
  modalColor: string;
  Message: string;
  isDataLoaded: boolean = false;
  isError: boolean = false;
  public search:any = '';
  @ViewChild('openModal') openModal: ElementRef;

  constructor(private formBuilder: FormBuilder, private router: Router, 
    private dataService: EventserviceService, private restService: RestService, private genService: GeneralService) {
    this.initializeForm()
  }

  ngOnInit() {
    this.getInvoice();
  }

  initializeForm() {
    this.accountForm = this.formBuilder.group({
      'bank': [null, Validators.required],
      'account_no': [null, Validators.required],
      'iban_no': [null, Validators.required]
    });
  }

  createAccount() {
    if (this.accountForm.value !== null) {
      this.restService.addBankDetails(this.accountForm.value).subscribe(
        res => {
          this.accountForm.reset()
          this.genService.showAlert(res.success,'alert-success')
        },
        err => {
          this.genService.showAlert(err.error['error'],'alert-danger')
        }
      );
    }
  }

  getInvoice() {
    this.restService.getInvoice().subscribe(
      res => {
          this.data = res;
          this.isDataLoaded = true;
      },
      err => {
        this.isDataLoaded = true;
        this.isError = true;
        if (err.status === 401 ) {
          this.router.navigate(['./logout']);
        }else {
          this.genService.showAlert(err.error['error'],'alert-danger')
        }
      }
    );
  }

}
