import { Directive, HostListener, Renderer2, ElementRef, Input } from '@angular/core';
import { Renderer3 } from '@angular/core/src/render3/renderer';
import { TouchSequence } from 'selenium-webdriver';

@Directive({
  selector: '[appOptioncard]'
})

export class OptioncardDirective {

  constructor(private renderer: Renderer2, private elmenetRef: ElementRef ) { }
  @Input('appOptioncard') optioncard: string;

  @HostListener('click', ['$event'])
  onMouseEnter() {
    let card = this.elmenetRef.nativeElement
    card.classList.contains('active-card')?
      this.hideElement(card):this.showElement(card)
  }

  showElement(card){
    this.renderer.addClass(card,'active-card')
    let elem = document.querySelector('.'+this.optioncard)
    if(elem){
      elem = elem as HTMLElement
      this.renderer.removeClass(elem,'hidden')
      elem.scrollIntoView()
    }
  }

  hideElement(card){
    this.renderer.removeClass(card,'active-card')
    let elem = document.querySelector('.'+this.optioncard)
    if(elem){
      elem = elem as HTMLElement
      this.renderer.addClass(elem,'hidden')
    }
  }
}
