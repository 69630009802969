import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tickettab',
  templateUrl: './tickettab.component.html',
  styleUrls: ['./tickettab.component.css']
})
export class TickettabComponent implements OnInit {

  @Input() ticketlist: any;
  constructor() { }

  ngOnInit() {
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }

}
