import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GuardService {

  errorMessage: string = '';
  errorType: string = '404';

  constructor(private router:Router) { }
  
  canActivate() {
    if (localStorage.getItem('currentUser')) {
        return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
    // not logged in so redirect to login page with the return url
  }

}
