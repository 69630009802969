import { Component, OnInit, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() modalColor: string = "";
  @Input() serverMessage: string = "";
  @ViewChild('modal') openModal;
   
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  launchModal() {

    this.renderer.addClass(this.openModal.nativeElement, this.modalColor);
    this.renderer.addClass(this.openModal.nativeElement, 'in');
    this.renderer.setStyle(this.openModal.nativeElement, 'display', 'block');
  }

  closeModal() {

    this.renderer.removeClass(this.openModal.nativeElement, this.modalColor);
    this.renderer.removeClass(this.openModal.nativeElement, 'in');
    this.renderer.setStyle(this.openModal.nativeElement, 'display', 'none');
  }

  addWarn(element) {
    this.renderer.removeClass(element.target, 'label-success')
    this.renderer.addClass(element.target, 'label-warning')
    element.target.removeAllListeners('click')
  }

  addSuccess(element) {
    this.renderer.addClass(element.target, 'label-success')
    this.renderer.removeClass(element.target, 'label-warning')
    element.target.removeAllListeners('click')
  }



}
