import {Deserializable} from "../deserializable.model";
import {User} from "../user/user.model";

export class Manageuser implements Deserializable {
    user: User[];
    deserialize(input: any) {
        Object.assign(this, input);
        this.user = input.user.map((user: User) => new User().deserialize(user))
        return this;
      }
}
