import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { EventRestService } from '../../../Service/eventrest/event-rest.service';
import { GeneralService } from '../../../Service/generalservice/general.service';
import { RestService } from '../../../Service/restapi/rest.service';

@Component({
  selector: 'app-vendortab',
  templateUrl: './vendortab.component.html',
  styleUrls: ['./vendortab.component.css']
})
export class VendortabComponent implements OnInit {

  @Input() vendorlist: any;
  modalColor: string = null;
  serverMessage: string = null;

  constructor(private eventRest: EventRestService, private renderer: Renderer2,
    private el: ElementRef, private genService: GeneralService,private restService: RestService ) { }

  ngOnInit() {
  }

  unverifyvendor(vendor_id,element){
    this.restService.unverifyvendor(vendor_id).subscribe(
      res => {
        this.serverMessage = res['success']
        this.modalColor = 'modal-success'
        this.genService.launchModal()
        this.genService.addWarn(element)
        element.target.innerText = 'Unverified'
        this.renderer.listen(element.target, 'click', (event) => {
          this.verifyvendor(vendor_id,event);
        })
      },
       err => {
        this.serverMessage = err['error']
        this.modalColor = 'modal-danger'
        this.genService.launchModal()
      }
    );
  }

  verifyvendor(vendor_id,element){
    this.restService.verifyvendor(vendor_id).subscribe(
      res => {
        this.serverMessage = res['success']
        this.modalColor = 'modal-success'
        this.genService.launchModal()
        this.genService.addSuccess(element)
        element.target.innerText = 'Verified'
        this.renderer.listen(element.target, 'click', (event) => {
          this.unverifyvendor(vendor_id,event);
        })
      },
       err => {
        this.serverMessage = err['error']
        this.modalColor = 'modal-danger'
        this.genService.launchModal()
      }
    );
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }


}
