import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { RequestOptions } from '@angular/http';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';
import {EventRestService} from '../../Service/eventrest/event-rest.service';


const API_URL = environment.apiUrl;

@Component({
  selector: 'app-event-statistic',
  templateUrl: './event-statistic.component.html',
  styleUrls: ['./event-statistic.component.css']
})
export class EventStatisticComponent implements OnInit {

  data: any = [];
  color: string = "red";
  serverMessage: string = "";
  modalColor: string = "modal-danger";
  isDataLoaded: boolean = false;
  isError: boolean = false;
  public search:any = '';
  
  @ViewChild('openModal') openModal:ElementRef;

  constructor( private route: ActivatedRoute, private router: Router,private http: HttpClient,
     private guardService: GuardService, private restService: RestService, private eventRest: EventRestService)
  { 
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
  }

  ngOnInit() {
    this.guardService.canActivate();
    let id = this.route.snapshot.paramMap.get('id');
    this.getStatistics(id);
  }

  getStatistics(id) {
    this.eventRest.getTicket_stats().subscribe(
       res => {
        this.isDataLoaded = true;
        this.data = res;
       },
        err => {
          this.isDataLoaded = true;
          if (err.status === 401) {
              this.router.navigate(['./logout']);
          }else {
            this.serverMessage = err.error['error'];
          }
          this.isError = true;
          this.launchModal();
       }
     );
  
  }

  launchModal(){
    this.openModal.nativeElement.click();
  }

}
