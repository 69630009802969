import { Component, OnInit, ViewChild, ElementRef, Renderer, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {  GuardService} from '../../Service/guard/guard.service';
import {RestService} from '../../Service/restapi/rest.service';
import {EventRestService} from '../../Service/eventrest/event-rest.service';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Service/generalservice/general.service';


@Component({
  selector: 'app-masterboard',
  templateUrl: './masterboard.component.html',
  styleUrls: ['./masterboard.component.css'],
})

export class MasterboardComponent implements OnInit {

  data: any = [];
  events: any = [];
  isDataLoaded: boolean = false;
  isError: boolean = false;
  vendor: String = null;
  modalColor: string = null;
  serverMessage: string = null;

  @ViewChild('modal') openModal;

  constructor(private elmenetRef: ElementRef,private http: HttpClient, private route: ActivatedRoute, private locat: Location, private restService: RestService,
     private guardService: GuardService,private genService:GeneralService, private router: Router, private eventRest: EventRestService,private renderer: Renderer2,private el: ElementRef) {      
  }

  ngOnInit() {

    this.getData();
    // this.openModal.nativeElement.style.display = 'block'
    // this.openModal.nativeElement.classList.remove('fade')
  }

  getData() {

    this.restService.masterboardData().subscribe(
       res => {
        this.data = res;
        this.isDataLoaded = true;
       },
        err => {
          this.isDataLoaded = true;
          this.isError = true;
       }
     );
  }

  checkDate(start_date){
    start_date = new Date(start_date);
    let today = new Date();
    if(start_date > today) return true
    return false;

  }

}
