import {Deserializable} from "../deserializable.model";
export class TicketCategory implements Deserializable {

    id: number;
    type: string;
    ticket_price: number;
    ticket_qty: number;
    cancellation_policy: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
