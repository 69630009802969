import { Injectable, Renderer2, ViewChild, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardService } from '../guard/guard.service';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx'
import { BootstrapAlertService, BootstrapAlert } from 'ngx-bootstrap-alert';

const API_URL = environment.apiUrl;

@Injectable()
export class GeneralService {


  private validateUrl = API_URL + "/auth/validatevendor";
  private facilityUrl = API_URL + "/event/facilities";
  private addVendorUrl = API_URL + "vendor/createvendor";
  private renderer: Renderer2;

  @ViewChild('modal') openModal;
  modalColor: string = null;

  constructor(private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private guardService: GuardService,
    private bootstrapAlertService: BootstrapAlertService, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  login(post): Observable<any> {

    const params = new FormData();
    params.append('email', post.vendorEmail);
    params.append('password', post.vendorPassword);
    return this.http
      .post(this.validateUrl, params)
      .map(response => {
        return response
      })
      .catch(error => {
        return this.errorHandling(error);
      })
  }

  errorHandling(error) {
    if (error.status != 401) {
      this.guardService.errorType = '401'
      this.guardService.errorMessage = error.error['error']
      return Observable.throw(error);
    }
    else {
      this.logout()
      this.router.navigateByUrl('logout');
    }
  }

  showAlert = (msg, ttype) => {
    var alert = new BootstrapAlert(msg, ttype);
    alert.timeoutInMiliSeconds = 5000;
    this.bootstrapAlertService.alert(alert);
  }

  addVendor(post) {
    const uploadData = new FormData();
    uploadData.append('username', post.username);
    uploadData.append('userPassword', post.userPassword);
    uploadData.append('userEmail', post.userEmail);
    uploadData.append('vendorEmail', post.vendorEmail);
    uploadData.append('fullname', post.fullname);
    uploadData.append('address', post.address);
    uploadData.append('organization', post.organization);
    return this.http
      .post(this.addVendorUrl, uploadData)
      .map(response => {
        return response
      })
      .catch(error => {
        console.log(error);
        return this.errorHandling(error);
      })

  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  launchModal() {

    this.renderer.addClass(this.openModal.nativeElement, this.modalColor);
    this.renderer.addClass(this.openModal.nativeElement, 'in');
    this.renderer.setStyle(this.openModal.nativeElement, 'display', 'block');
  }

  closeModal() {

    this.renderer.removeClass(this.openModal.nativeElement, this.modalColor);
    this.renderer.removeClass(this.openModal.nativeElement, 'in');
    this.renderer.setStyle(this.openModal.nativeElement, 'display', 'none');
  }

  addWarn(element) {
    this.renderer.removeClass(element.target, 'label-success')
    this.renderer.addClass(element.target, 'label-warning')
    element.target.removeAllListeners('click')
  }

  addSuccess(element) {
    this.renderer.addClass(element.target, 'label-success')
    this.renderer.removeClass(element.target, 'label-warning')
    element.target.removeAllListeners('click')
  }



}
