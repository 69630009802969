import { Component, OnInit } from '@angular/core';
import {  GuardService} from '../../Service/guard/guard.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  errorMessage: string = '';
  errorType: string = '404';
  constructor(private guardService: GuardService) {
    this.errorMessage = this.guardService.errorMessage
    this.errorType = this.guardService.errorType;
   }

  ngOnInit() {
  }

}
