import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Options } from 'ng5-slider';
import { EventRestService } from '../../Service/eventrest/event-rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardService } from '../../Service/guard/guard.service';
import { Managecoupon } from '../../Model/managecoupon/managecoupon.model';
import { Eventcoupon } from '../../Model/eventcoupon/eventcoupon.model';
import { TicketCategory } from '../../Model/ticketcategory/ticket-category.model';
import { Shortevent } from '../../Model/shortevent/shortevent.model';

@Component({
  selector: 'app-managecoupon',
  templateUrl: './managecoupon.component.html',
  styleUrls: ['./managecoupon.component.css']
})
export class ManagecouponComponent {

  value: number = 5;
  options: Options = {
    floor: 5,
    ceil: 100,
    step: 5,
    translate: (value: number): string => {
      return value + "%";
    }
  };
  @ViewChild('openModal') openModal: ElementRef;
  couponForm: FormGroup;
  data: Managecoupon;
  coupondata: Eventcoupon[];
  tickets: TicketCategory[];
  isDataLoaded: boolean = false;
  isError: boolean = false;
  eventdata: Shortevent;
  selectedTicket: any;
  event_id: string;
  serverMessage: string;
  modalColor: string = "";

  constructor(private formBuilder: FormBuilder, private eventRest: EventRestService, private router: Router, private guardService: GuardService, private route: ActivatedRoute, ) {
  }

  ngOnInit() {
    this.guardService.canActivate();
    this.route.queryParams.subscribe(
      params => this.event_id = params['event']);
    this.getCoupon();
  }

  postCoupon() {

    var formVal = this.couponForm.getRawValue()
    var coupondata = new Eventcoupon();
    var type;
    for (var i = 0; i < formVal.tickets.length; i++) {
      if (formVal.tickets[i]) {
        coupondata.ticket_id = this.tickets[i].id;
        type = this.tickets[i].type;
        break;
      }
    }
    coupondata.coupon = null;
    coupondata.event_id = parseInt(this.event_id);
    coupondata.coupon = formVal.coupon;
    coupondata.status = 1;
    coupondata.coupon_limit = formVal.coupon_limit;
    coupondata.type = type;
    coupondata.discount = this.value;
    this.eventRest.addEventCoupon(coupondata).subscribe(
      res => {
        this.coupondata.push(coupondata)
        this.createForm();
        this.modalColor = "modal-success";
        this.serverMessage = res.success;
        this.launchModal();
      },
      err => {
        if (err.status === 401) {
          this.router.navigate(['./logout']);
        }
        else {
          this.serverMessage = err.error['error'];
          this.modalColor = "modal-danger";
          this.launchModal();
        }
      });
  }

  launchModal() {
    this.openModal.nativeElement.click();
    // this.router.navigate(['./event/manage']);
  }

  private changeTicket(event, index) {
    var ticketList = this.couponForm.value.tickets
    if (event.target.checked === true) {
      for (var i = 0; i < ticketList.length; i++) {
        if (ticketList[i] === true && i !== index) {
          ticketList[i] = false;
          this.couponForm.controls['tickets']['controls'][i].setValue(false);
        }
      }
    }
  }

  private createForm() {
    this.couponForm = this.formBuilder.group({
      tickets: new FormArray([]),
      'coupon': new FormControl({ 'value': this.randomString(), disabled: true }),
      'coupon_limit': [0, Validators.compose([Validators.required, Validators.pattern("[1-9]\[0-9]*"), Validators.maxLength(6)]),],

    });
    this.addCheckboxes();
  }

  private getCoupon() {
    this.eventRest.geteventcoupon().subscribe(
      res => {
        this.coupondata = res.eventcoupon;
        this.tickets = res.ticketcategory;
        this.eventdata = res.event;
        this.isDataLoaded = true;
        this.createForm();
      },

      err => {
        this.serverMessage = err.error['error'];
        this.modalColor = "modal-danger";
        if (err.status === 401) {
          this.router.navigate(['./logout']);
        }
        else {
          this.launchModal();
        }
      });
  }

  private addCheckboxes() {
    this.tickets.map((o, i) => {
      const control = new FormControl(i === 0); // if first item set to true, else false
      (this.couponForm.controls.tickets as FormArray).push(control);
    });
  }

  private randomString() {
    var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i <= 8; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

}
