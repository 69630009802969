import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl, FormArray, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { EventserviceService } from '../../Service/eventservice/eventservice.service';
import { RestService } from '../../Service/restapi/rest.service';
import { GeneralService } from '../../Service/generalservice/general.service';

@Component({
  selector: 'app-vendor-login',
  templateUrl: './vendor-login.component.html',
  styleUrls: ['./vendor-login.component.css']
})
export class VendorLoginComponent implements OnInit {
  [x: string]: any;

  @ViewChild('openModal') openModal:ElementRef;
  loginForm: FormGroup;
 

  constructor(private formBuilder: FormBuilder, private router: Router, private generalService: GeneralService,
    private dataService:EventserviceService) {
    this.createForm();
  }

  ngOnInit() {
    this.generalService.logout();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      'vendorEmail':[null, Validators.email],
      'vendorPassword':[null, Validators.required]
    });    
  }

  validateVendor(post) {
    this.generalService.login(post).subscribe(
       res => {
        localStorage.setItem('currentUser', JSON.stringify(res));
        this.router.navigate(['./dashboard'],{ queryParams: {vendor: res.user.vendor.id} });
       },
        err => {
         this.errorMessage = err.error['error'];
         this.modalColor = "modal-danger"; 
         this.launchModal();  
       }
     );
  }

  launchModal(){
    this.openModal.nativeElement.click();
  }

  

}
